import { graphql, Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import * as headerStyles from '../styles/common.module.css';

/*
Note:
* Any update to strapi or postgres db needs gatsby restart
* For localhost:8000/__graphql use following:
{allStrapiPost {P
      edges {
        node {
          id
          title
          body
          className
        }
      }
    }
}
*/
export const pageQuery = graphql`
  query {
    termsParagraphs:allStrapiPost(filter: {page_name: {eq: "termsandconditions"}}) {
      edges {
        node {
          id
          title
          body
          className,
          category
        }
      }
    }
  }
`;
function formatData( data ) {
	let tempDictionary = {};
	let outputArray = [];
	let title = 'legal-info-para';// headerStyles.head
	// console.log("termsparagraph" ,data.termsParagraphs);

	data.termsParagraphs.edges.forEach( ( edge, index ) => {
		// className: edge.node.className,
		//console.log("edge" ,edge,title,edge.node.title,edge.node.title.includes(title),edge.node.title.startsWith(title));
		if ( edge.node.title.includes( title ) ) {
			tempDictionary[edge.node.title] = {
				className: edge.node.className, body: edge.node.body
			};
			//console.log("tempDictionary",tempDictionary[edge.node.title]);
		}
	} );
	//console.log("### tempDictionary: ", tempDictionary);

	for ( let counter = 0; counter < Object.keys( tempDictionary ).length; counter += 1 ) {
		let key = `${title}${counter}`;
		if ( tempDictionary[key] ) {
			outputArray.push( { className: tempDictionary[key].className, body: tempDictionary[key].body } );
		}
		//  outputArray.push({ className: tempDictionary[key].className, body: tempDictionary[key].body });
	}
	return outputArray;
}
const TermsandConditionsPage = ( { data } ) => (
	<Layout>
		<Seo
			title="Terms and Conditions"
			description="Page three description"
			robots={{ 1:'noindex', 2: 'nofollow' }}
		/>

		<div className="wrapper">
			<div className="termsconditionwrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<header>
								<nav>
									<ul className="nav justify-content-center page-tab-head" >
										<li className="nav-item">
											<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/termsandconditions"><u>Terms</u></Link>
										</li>

										<li className="nav-item">
											<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/privacy-policy">Privacy Policy</Link>
										</li>

										<li className="nav-item">
											<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/accessibility">Accessibility</Link>
										</li>
									</ul>
								</nav>
							</header>
						</div>
					</div>

					<div className="row mgt-50">
						<div className="col-12">
							{formatData( data ).map( ( d, i ) => {
								return <div className={d.className} key={i}>{d.body}</div>;
							} )}
						</div>

						<div className="col-12">
							<Link to="/">Go back to the homepage</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
);
export default TermsandConditionsPage;
